import React from "react";
import BaseComponent from "../../BaseComponent";
import DatePicker from "react-datepicker";

import './UserDetailsComponent.css';
import "react-datepicker/dist/react-datepicker.css";
import {Chronos} from "../../../entity/Chronos";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";

class UserDetailsComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        this.initState({
            roles : [],
            rolesNetworkInFlight : false,
            clinicalRoles : []
        });
    }

    componentDidMount() {
        this.fetchUserRolesFromNetwork();
        this.fetchClinicalRolesFromNetwork();
    }

    fetchUserRolesFromNetwork = () => {
        if (this.state.rolesNetworkInFlight) return;
        this.setState({
            rolesNetworkInFlight : true
        });

        Axios.get(ENDPOINTS.user.getUserRoles)
            .then((r) => {
                let resp = API.parse(r);

                let roles = [];

                if (resp.success) {
                    roles = resp.data.userRoles;
                }

                this.setState({
                    rolesNetworkInFlight : false,
                    roles
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    fetchClinicalRolesFromNetwork = () => {
        if (this.state.clinicalRolesNetworkInFlight) return;
        this.setState({
            clinicalRolesNetworkInFlight : true
        });

        const requestData = new FormData()
        requestData.append("filterList", 1);

        Axios.post(ENDPOINTS.caseRecord.getClinicalRoles, requestData)
            .then((r) => {
                let resp = API.parse(r);

                let clinicalRoles = [];

                if (resp.success) {
                    clinicalRoles = resp.data.clinicalRoles;
                }

                this.setState({
                    clinicalRolesNetworkInFlight : false,
                    clinicalRoles
                });
            })
            .catch((e) => {
                console.log("ERROR LOADING CLINICAL ROLES");
                console.log(e);
            });
    }

    // MARK: Actions

    handleChange = (e) => {
        let user = this.props.user;
        user[e.target.id] = e.target.value;

        if (this.props.hasOwnProperty("userStateDidChange")) {
            this.props.userStateDidChange(user);
        }
    }

    handleDateChange = (id, date) => {
        let outDate = date.getTime() / 1000;

        let user = this.props.user;
        user[id] = outDate;

        if (this.props.hasOwnProperty("userStateDidChange")) {
            this.props.userStateDidChange(user);
        }
    }

    // MARK: Render

    render() { 
        let user = this.props.user;
        if (user == null) { user = {}; } // Prevent crash from user being null (important when creating a user)

        return (
            <div className={"user-details"}> 
                {this.renderPersonalDetails(user)}
                {this.renderMedicalRoleDetails(user)}
                {this.renderContactDetails(user)}
                {this.renderNotificationPreferences(user)}
            </div>
        )
    }

    /*
    <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"card user-detials-section no-margin"}>
                            <div className={"row"}>
                                <div className={"col-12 col-md-6"}>
                                    <label>Password (leave blank to keep the same)</label>
                                    <input type={"text"} className={"form-control"} name={"password"} value={this.state.password} onChange={this.handleChange} />
                                </div>

                                <div className={"col-12 col-md-6"}>
                                    <label>Confirm Password</label>
                                    <input type={"text"} className={"form-control"} name={"passwordConfirm"} value={this.state.passwordConfirm} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
     */

    // MARK: Render Helper Methods

    renderPersonalDetails(user) {
        if (user == null) { return }
        
        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card user-details-section no-margin"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"section-header"}>{this.fullName(user)}</div>
                                <div className={"section-title"}>Personal Details</div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>First Name</label>
                                        <input type="text" className={"form-control"} id="givenName" defaultValue={user.givenName} onChange={e => this.handleChange(e)}/>
                                    </div>
                                </div>

                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Last Name</label>
                                        <input type="text" className={"form-control"} id="familyName" defaultValue={user.familyName} onChange={e => this.handleChange(e)}/>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Age Band</label><br />
                                        <select
                                            id={"ageBand"}
                                            name={"ageBand"}
                                            className={"form-control"}
                                            value={user.ageBand}
                                            onChange={this.handleChange}
                                        >
                                            <option value={"<30"}>&lt;30</option>
                                            <option value={"30-40"}>30-40</option>
                                            <option value={"40-50"}>40-50</option>
                                            <option value={"50-60"}>50-60</option>
                                            <option value={">60"}>&gt;60</option>
                                        </select>
                                    </div>
                                </div>

                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>GMC Number</label>
                                        <input type="text" className={"form-control"} id="gmcNumber" defaultValue={user.gmcNumber} onChange={e => this.handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderMedicalRoleDetails(user) {
        if (user == null) { return }

        let roleElem = [];
        let placeOfWorkReadOnly = false;
        if (user.id) {
            let jobRoleText = "---";
            if (this.state.clinicalRoles && user.clinicalRoleId) {
                for (let i = 0; i < this.state.clinicalRoles.length; i++) {
                    if (parseInt(user.clinicalRoleId) === parseInt(this.state.clinicalRoles[i].id)) {
                        jobRoleText = this.state.clinicalRoles[i].name;
                        break;
                    }
                }
            }

            roleElem = (
                <div className={"form-control"}>
                    {jobRoleText}
                </div>
            )

            placeOfWorkReadOnly = true;
        } else {
            roleElem = (
                <select
                    className={"form-control"}
                    id={"clinicalRoleId"}
                    value={user.clinicalRoleId}
                    onChange={this.handleChange}
                >
                    <option value={null}>Please Select</option>
                    {
                        this.state.clinicalRoles.map((role) =>
                            <option value={role.id}>{role.name}</option>
                        )
                    }
                </select>
            )
        }

        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card user-details-section"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"section-title"}>Details of current NHS Duties</div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Primary NHS Role</label>
                                        {roleElem}
                                    </div>
                                </div>

                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Place of Work</label>
                                        <input type="text" className={"form-control"} defaultValue={user.organisationName} readOnly={placeOfWorkReadOnly} />
                                    </div>
                                </div>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Expected End Date</label><br />
                                        <DatePicker
                                            selected={(this.props.user.expectedEndDate != null) ? Chronos.withTimestampSeconds(this.props.user.expectedEndDate).getDate() : null}
                                            onChange={(date) => this.handleDateChange("expectedEndDate", date)}
                                            className={"form-control"}
                                            dateFormat={"dd/MM/yyyy"} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    renderContactDetails(user) {
        if (user == null) { return [] }

        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card user-details-section"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"section-title"}>Contact and Account Details</div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>Email</label>
                                        <input type="text" className={"form-control"} id="emailAddress" defaultValue={user.emailAddress} onChange={e => this.handleChange(e)} />
                                    </div>
                                </div>
                            </div>

                            <div className={"col-12 col-md-6"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>QIN Account Type</label>
                                        <select className={"form-control"} id={"userRoleId"} value={user.userRoleId} onChange={this.handleChange}>
                                            <option value={-1}>Please Select</option>
                                            {
                                                this.state.roles.map((role) => (
                                                    <option value={role.id}>{role.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

    getNotificationPreference = (user, keyName, defaultValue) => {
        console.log("USER", user);
        if (user && user.hasOwnProperty("notificationPreferences")) {
            if (user.notificationPreferences.hasOwnProperty(keyName)) {
                return user.notificationPreferences[keyName];
            }
        }
        return defaultValue;
    }

    setNotificationPreference = (user, keyName, value) => {
        if (user && user.hasOwnProperty("notificationPreferences")) {
            user.notificationPreferences[keyName] = value;
        }
        this.setState({
            mutation : Math.random()
        });

        if (this.props.hasOwnProperty("userStateDidChange")) {
            this.props.userStateDidChange(user);
        }
    }

    renderNotificationPreferences = (user) => {
        if (user == null) { return [] }

        const notificationOptions = [
            { name : "admitEd", label : "Patients admitted via the Emergency Department (ED/A+E)." },
            { name : "ctScan", label : "Patients where a CT scan was performed (includes patients where NELA record returns ‘Unknown’ regarding the performance of a CT scan)." },
            { name : "highRisk", label : "Patients assessed as ‘High-Risk’ pre-op in NELA." },
            { name : "assessRCS", label : "Patients assessed as meeting RCS criteria for ‘Immediate Surgery’ (includes patients where QIN-EmLap assesses the RCSE category as ‘indeterminable’)." },
            { name : "sepsisIdent", label : "Patients assessed as having sepsis identified pre-op (Includes patients where NELA record returns ‘unknown’ for presence of sepsis/septic shock)." },
            { name : "elderCR", label : "Patients meeting NELA criteria for elderly care review (includes patients where NELA record indicates frailty was not assessed)." }
        ];

        const notificationElems = [];
        notificationOptions.forEach((option) => {
            notificationElems.push(
                <label className={"list-group-item"}>
                    <input
                        type={"checkbox"}
                        className={"mr-2"}
                        name={option.name}
                        checked={parseInt(this.getNotificationPreference(user, option.name, 0)) === 1}
                        onChange={(ev) => {
                            this.setNotificationPreference(
                                user,
                                option.name,
                                ev.target.checked ? 1 : 0
                            )
                        }}
                    />

                    {option.label}
                </label>
            )
        });

        return (
            <div className={"row"}>
                <div className={"col-12"}>
                    <div className={"card user-details-section"}>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"section-title"}>Notification Preferences</div>
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"col-12"}>
                                <div className={"row input-field"}>
                                    <div className={"col-12"}>
                                        <label>User will receive QIN-EmLap Case Review Notifications for the following patient groups.</label>
                                        <div className={"list-group"}>
                                            {notificationElems}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    fullName = (user) => {
        // The snippet below is to include middle name
        // if (user.givenName && user.middleName && user.familyName) {
        //     console.log("here");
        //     return user.givenName + " " + user.middleName + " " + user.familyName;

        // } else if (user.givenName && user.middleName) {
        //     return user.givenName + " " + user.middleName;

        // } else 
        if (user.givenName && user.familyName) {
            return user.givenName + " " + user.familyName;

        } else if (user.givenName) {
            return user.givenName;

        } else {
            return ""
        }
    }
}
 
export default UserDetailsComponent;

/*
<div className={"row input-field"}>
    <div className={"col-12"}>
        <label>Middle Name(s)</label>
        <input type="text" className={"form-control"} id="middleName" defaultValue={user.middleName} onChange={e => this.handleChange(e)}/>
    </div>
</div>

<div className={"row input-field"}>
    <div className={"col-12"}>
        <label>Phone Number</label>
        <input type="text" className={"form-control"} id="phoneNumber" defaultValue={user.phoneNumber} onChange={e => this.handleChange(e)} />
    </div>
</div>
 */