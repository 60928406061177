import React from "react";
import BaseComponent from "../BaseComponent";
import TableComponent, {Column, TablePaginator} from "../common/TableComponent";
import AlertModal from "../common/AlertModal";

import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import './UserAccounts.css';
import SegmentedButtonComponent from "../common/SegmentedButtonController";
import {Chronos} from "../../entity/Chronos";
import AutoAssignEditorModalComponent from "./AutoAssignEditorModalComponent";
import BlockUI from "react-block-ui";
import {UserApprovalsScreen} from "./approvals/UserApprovalsScreen";
import {DataManager} from "../../util/DataManager";


class UserAccountsComponent extends BaseComponent {

    MODE_USERS = 1;
    MODE_AUTO_ASSIGN = 2;
    MODE_APPROVAL = 3;

    keywordFilterTimeout = undefined;
    pageLimit = 10;

    constructor(props, context) {
        super(props, context);

        const user = DataManager.getUser();

        this.initState({
            users : [],
            userRoleId : user.userRoleId,
            networkInFlight : false,
            filterKeyword : "",
            currentPage : 1,
            totalRows : 0,
            mode : this.MODE_USERS,
            fetchMode : null,
            modalOpen : false,
            modalTitle : null,
            modalContent : null,
            modalButtons : null,
            autoAssignRecords : [],
            recordsCount : 0,
            autoAssignPage : 1,
            autoAssignKeyword : "",
            autoAssignOpen : false,
            autoAssignRecord : undefined
        });

        this.prepareRequestFormForNetwork = this.prepareRequestFormForNetwork.bind(this);
    }


    // MARK: Life-cycle

    componentDidMount() {
        this.props.title("User Accounts");

        if (this.state.mode === this.MODE_USERS) {
            this.fetchUsersFromNetwork();
        } else {
            this.fetchAutoAssignTableDataFromNetwork();
        }

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.fetchMode !== this.state.fetchMode) {
            if (this.state.currentPage > 1) {
                this.setState({
                    currentPage : 1
                }, () => {
                    this.fetchUsersFromNetwork();
                })
            } else {
                this.fetchUsersFromNetwork();
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.filterKeyword);
    }


    // MARK: Actions

    searchInputDidChange = (e) => {
        let keyword = e.target.value;
        if (keyword == null) {
            keyword = "";
        }
        console.log("SET TIMEOUT");
        clearTimeout(this.keywordFilterTimeout);
        this.keywordFilterTimeout = setTimeout(() => {
            console.log("TIMEOUT!");
            this.setState({
                filterKeyword : keyword
            })
            this.fetchUsersFromNetwork();
        }, 600);

        this.handleChange(e);
    }

    didSelectRow(row) {
        // console.log("Row clicked")
    }

    updateTablePage = (index) => {
        this.setState({
            currentPage : index
        });

        this.fetchUsersFromNetwork(index)
    }


    // MARK: Alerts

    showModal(title, message, buttons) {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal() {
        this.setState({
            modalOpen : false
        });
    }


    // MARK: Navigation

    moveToCreateNewUser() {
        console.log("Create new user clicked.")
        let url = "/users/new";

        this.props.history.push(url);
    }

    moveToViewSelectedUser(userId) {
        console.log("View selected user: " + userId)
        let url = "/users/view";
        if (userId !== undefined) {
            url += "/" + userId;
        }

        this.props.history.push(url);
    }


    // MARK: Networking

    prepareRequestFormForNetwork(page) {
        if (page === undefined) {
            page = this.state.currentPage;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("limit", this.pageLimit);

        if (this.state.filterKeyword !== "") { 
            formData.append("keyword", this.state.filterKeyword);
        }
        return formData;
    }

    fetchUsersFromNetwork(page) {
        if (this.state.networkInFlight) return;
        this.setState({
            networkInFlight : true
        });

        let formData = this.prepareRequestFormForNetwork(page)

        if (this.state.fetchMode !== null) {
            formData.append("mode", this.state.fetchMode);
        }

        Axios.post(ENDPOINTS.user.getAllUsers, formData)
            .then( (r) => {
                let totalRows = 0;
                let users = null;

                let response = API.parse(r);
                switch (response.success) {
                    case true:
                        totalRows = response.data.total;
                        users = response.data.users;
                        break;

                    case false:
                        this.showModal(
                            "Error",
                            response.error.desc + "[" + response.error.code + "]"
                        );
                        break;
                }

                this.setState({
                    networkInFlight: false,
                    totalRows : totalRows,
                    users : users
                });

            }).catch( (error) => {
                console.log(error);
            })
    }


    modeDidChange = (mode) => {
        this.setState({
            mode
        }, () => {
            if (this.state.mode === this.MODE_USERS) {
                this.fetchUsersFromNetwork();
            } else {
                this.fetchAutoAssignTableDataFromNetwork();
            }
        });
    }

    fetchAutoAssignTableDataFromNetwork = (page, keyword) => {
        if (this.state.autoAssignNetworkInFlight) return;
        this.setState({
            autoAssignNetworkInFlight : true
        })

        if (page === undefined) {
            page = this.state.autoAssignPage;
        }

        if (keyword === undefined) {
            keyword = this.state.autoAssignKeyword;
        }

        let formData = new FormData();
        formData.append("page", page);
        formData.append("keyword", keyword);

        Axios.post(ENDPOINTS.caseRecord.getCaseRecordAutoAssignRecords, formData)
            .then((r) => {
                let resp = API.parse(r);

                let autoAssignRecords = [];
                let recordsCount = 0;

                if (resp.success) {
                    autoAssignRecords = resp.data.records;
                    recordsCount = resp.data.count;
                } else {
                    // TODO Error
                    console.log(resp.error);
                }

                this.setState({
                    autoAssignNetworkInFlight : false,
                    autoAssignRecords,
                    recordsCount
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onAutoAssignRecordSelected = (row) => {
        this.setState({
            autoAssignOpen : true,
            autoAssignRecord : row
        });
    }

    autoAssignDidDismiss = () => {
        this.setState({
            autoAssignPage : 1,
            autoAssignKeyword : "",
            autoAssignOpen : false
        }, () => {
            this.fetchAutoAssignTableDataFromNetwork();
        });
    }

    onAssignPageChanged = (page) => {
        this.setState({
            autoAssignPage : page
        }, () => {
            this.fetchAutoAssignTableDataFromNetwork();
        });
    }

    promptToRemoveAutoAssign = (row) => {
        this.showModal("Confirm Removal", "Are you sure you wish to remove " + row.givenName + " " + row.familyName + " from Auto Assign? This action cannot be undone.", [
            {
                label : "Remove",
                style : "btn-danger",
                click : () => {
                    this.hideModal();

                    this.removeAutoAssignOverNetwork(row.id);
                }
            },
            {
                label : "Cancel",
                click : () => {
                    this.hideModal();
                }
            }
        ])
    }

    removeAutoAssignOverNetwork = (id) => {
        if (this.state.autoAssignNetworkInFlight) return;
        this.setState({
            autoAssignNetworkInFlight : false
        });

        let formData = new FormData();
        formData.append("id", id);

        Axios.post(ENDPOINTS.caseRecord.removeCaseRecordAutoAssign, formData)
            .then((r) => {
                let fetchAfter = true;

                let resp = API.parse(r);
                if (!resp.success) {
                    fetchAfter = false;
                    this.showError(resp.error.desc + " [" + resp.error.code + "]");
                }

                this.setState({
                    autoAssignNetworkInFlight : false
                }, () => {
                    if (fetchAfter) {
                        this.fetchAutoAssignTableDataFromNetwork();
                    }
                });
            })
            .catch((e) => {
                this.showError("An unknown error occurred while removing Auto Assign Record. Please try again later. [A381]");
            });
    }

    showError = (message) => {
        this.showModal("Error", message);
    }

    // MARK: Render

    render() {
        let contentUI = [];

        if (this.state.mode === this.MODE_USERS) {
            contentUI.push(this.renderHeader());
            contentUI.push(this.renderUserTable());
        } else if (this.state.mode === this.MODE_APPROVAL) {
            contentUI.push(<UserApprovalsScreen />);
        } else if (this.state.mode === this.MODE_AUTO_ASSIGN) {
            contentUI = (
                <BlockUI tag="div" blocking={this.state.assignNetworkInFlight}>
                    <div className={"row pd-4"}>
                        <div className={"col-12 pt-4"}>
                            <div className="alert alert-primary" role="alert">
                                Auto Assign Users are Users that will be added every time a Case Record is created.
                            </div>
                        </div>

                        <div className={"col-12 pt-2"}>
                            <button className={"btn btn-primary"} onClick={() => this.onAutoAssignRecordSelected(undefined)}>Add Assign Record</button>
                        </div>

                        <div className={"col-12 pt-4"}>
                            <TableComponent
                                className={"common-table alternating clickable"}
                                emptyMessage={"No Auto Assign records to display"}
                                data={this.state.autoAssignRecords}>

                                <Column name={"givenName"} title={"User Name"} render={(data, row) => {
                                    return row.givenName + " " + row.familyName;
                                }}/>
                                <Column name={"roleName"} title={"Clinical Role"} />
                                <Column name={"id"} title={"Actions"} className={"text-center"} render={(data, row) => {
                                    let buttons = [];
                                    buttons.push(<span className={"btn btn-primary"} onClick={() => this.onAutoAssignRecordSelected(row)}>Change</span>);
                                    buttons.push(<span>&nbsp;</span>);
                                    buttons.push(<span className={"btn btn-danger"} onClick={() => this.promptToRemoveAutoAssign(row)}>Remove</span>);

                                    return buttons;
                                }}/>

                            </TableComponent>

                            <div className={"ep-table-paginate-container pt-4"}>
                                <div className={"push"}/>
                                <div className={"paginator-area"}>
                                    <TablePaginator
                                        totalCount={this.state.recordsCount}
                                        pageSize={20}
                                        page={this.state.currentPage}
                                        onClick={this.onAssignPageChanged} />
                                </div>
                            </div>
                        </div>
                    </div>
                </BlockUI>
            )
        }

        /*
        ,
                                {
                                    id: this.MODE_AUTO_ASSIGN,
                                    label: "Auto Assign"
                                }
         */

        return (
            <div className={"user-accounts"}>
                <div className={"row mt-4 justify-content-center"}>
                    <div className={"col-12 col-md-8 col-lg-6"}>
                        <SegmentedButtonComponent
                            buttons={[
                                {
                                    id: this.MODE_USERS,
                                    label: "Users"
                                },
                                {
                                    id : this.MODE_APPROVAL,
                                    label : "Approvals"
                                }
                            ]}
                            selectedId={this.state.mode}
                            callback={this.modeDidChange}/>
                    </div>
                </div>
                {contentUI}
                {this.renderAlertModal()}

                <AutoAssignEditorModalComponent
                    open={this.state.autoAssignOpen}
                    record={this.state.autoAssignRecord}
                    callback={this.autoAssignDidDismiss} />


            </div>
        )
    }


    // MARK: Render Helpers

    formatName = (row) => row.givenName + " " + row.familyName;

    renderHeader() {
        let modeElem = [];

        if (API.isInRoles(parseInt(this.state.userRoleId), [API.userRoles.SUPER_ADMIN, API.userRoles.SYSTEM_ADMIN])) {
            modeElem = (
                <div className={"row mt-2 justify-content-center"}>
                    <div className={"col-12 col-lg-10 col-xl-8"}>
                        <SegmentedButtonComponent
                            buttons={[
                                {
                                    id: null,
                                    label: "Active Users"
                                },
                                {
                                    id : "deactivated",
                                    label : "Deactivated Users"
                                }
                            ]}
                            selectedId={this.state.fetchMode}
                            callback={(fetchMode) => this.setState({ fetchMode })}/>
                    </div>
                </div>
            )
        }

        return (
            <>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"actions"}>
                            <div className={"action"}>
                                <span className={"btn btn-primary"} onClick={() => this.moveToCreateNewUser()}>Add a New User</span>
                            </div>
                            <div className={"spacer"} />
                            <div className={"action"}>
                                <div className={"input-group"}>
                                    <div className={"input-group-prepend"}>
                                        <span className={"input-group-text"}>Search</span>
                                    </div>
                                    <input type={"text"} className={"form-control"} name={"filter-keyword"} onChange={this.searchInputDidChange} placeholder={"Search users"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {modeElem}
            </>
        );
    }

    renderUserTable() {
        return (
            <div className={"row table-area"}>
                <div className={"col-12"}>
                    <TableComponent
                        className={"common-table alternating clickable"}
                        emptyMessage={"There are no users to display"}
                        data={this.state.users}
                        onRowClick={ (row) => { this.didSelectRow(row, true);}}>

                        <Column name={"givenName"} title={"User Name"} render={ (data, row) => this.formatName(row) } />
                        <Column name={"role"} title={"Default Clinical Role"} className={"text-center"} />
                        <Column name={"userType"} title={"QIN Account Type"} className={"text-center"} />
                        <Column name={"userId"} title={"Actions"} className={"text-center"} render={ (data, row) => this.renderActionButtons(row) } />
                    </TableComponent>

                    <div className={"paginator-container"}>
                        <div className={"spacer"} />
                        <div className={"paginator-area"}>
                            <TablePaginator 
                                totalCount={this.state.totalRows}
                                pageSize={this.pageLimit}
                                page={this.state.currentPage}
                                onClick={ (idx) => this.updateTablePage(idx) } />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    renderActionButtons = (row) => {
        let buttons = [];
        buttons.push(<span className={"btn btn-primary"} onClick={ (event) => { this.moveToViewSelectedUser(row.userId) }}>View</span>);
        return buttons;
    }

    renderAlertModal() {
        return (
            <AlertModal
                open={this.state.modalOpen}
                title={this.state.modalTitle}
                content={this.state.modalContent}
                buttons={this.state.modalButtons}
                dismissHandler={this.hideModal} 
            />
        )
    }
}

export default UserAccountsComponent;