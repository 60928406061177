import React from "react";
import BaseComponent from "../BaseComponent";

import UserDetailsComponent from './User Details Component/UserDetailsComponent';
import AdminDetailsComponent from "./Admin Details Component/AdminDetailsComponent";
import AlertModal from "../common/AlertModal";

import Axios from "axios";
import {API, ENDPOINTS} from "../../network/API";

import './UserAccounts.css';

class NewUserComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        
        this.initState({
            user : {},
            hasDashboardAccount : false,
            userIsReadyForSubmission : false,
            submissionInProgress: false
        });
    }


    // MARK: Life-cycle

    componentDidMount() {
        this.props.title("Create New User");

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    componentWillUnmount() {

    }


    // MARK: Actions

    userStateDidChange(user) {
        this.setState({
            user : user
        });

        this.updateUserSubmissionState();
    }

    dashboardAccountStateDidChange(hasDashboardAccount) {
        this.setState({
            hasDashboardAccount : hasDashboardAccount
        });
    }

    updateUserSubmissionState() {
        let user = this.state.user;
        if (this.state.userIsReadyForSubmission) {
            this.setState({
                userIsReadyForSubmission : false
            });
        }

        // && user.clinicalRoleId
        //             && user.placeOfWork
        //             && user.gmcNumber
        if (user.givenName
            && user.familyName
            && user.expectedEndDate
            && user.emailAddress) {
            this.setState({
                userIsReadyForSubmission : true
            });
        }
    }

    saveTapped() {
        if (!this.state.userIsReadyForSubmission) { return }
        
        // Make save request
        this.createNewUser();
    }

    cancelTapped() {
        this.showModal(
            "Warning",
            "Are you sure you wish to cancel? This user will NOT be saved and any progress will be lost.",
            [
                {
                    label : "Yes",
                    click : () => {
                        this.navigateBack();
                        this.hideModal();
                    }
                }, 
                {
                    label : "No",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        );
    }


    // MARK: Alerts

    showModal(title, message, buttons) {
        if (buttons === undefined) {
            buttons = [
                {
                    label : "OK",
                    click : () => {
                        this.hideModal();
                    }
                }
            ]
        }

        this.setState({
            modalOpen : true,
            modalTitle : title,
            modalContent : message,
            modalButtons : buttons
        });
    }

    hideModal() {
        this.setState({
            modalOpen : false
        });
    }


    // MARK: Navigation

    navigateBack() {
        this.props.history.goBack();
    }

    navigateToUser(userId, forgottenPasswordUrl) {
        let url = "/users/view";
        if (userId !== undefined) {
            url += "/" + userId;
        }

        if (forgottenPasswordUrl !== undefined) {
            url += "#forgottenPasswordUrl=" + encodeURI(forgottenPasswordUrl);
        }

        this.props.history.push(url);
    }


    // MARK: Networking

    createNewUser() {
        if (this.state.submissionInProgress) { return }
        this.setState({
            submissionInProgress : true
        });
        let formData = this.prepareUserRequestForm()

        Axios.post(ENDPOINTS.user.submitUser, formData)
            .then( (r) => {
                let response = API.parse(r);
                switch (response.success) {
                    case true:
                        let newUser = response.data.user;
                        let forgottenPasswordUrl = response.data.forgottenPasswordUrl;
                        this.navigateToUser(newUser.id, forgottenPasswordUrl);
                        break;

                    case false:
                        this.showModal(
                            "Error",
                            response.error.desc + "[" + response.error.code + "]"
                        );
                        console.log(response.error);
                        break;
                }

                this.setState({
                    submissionInProgress : false
                });

            }).catch( (error) => {
                console.log(error);
            })
        
    }

    prepareUserRequestForm() {
        let formData = new FormData();
        let user = this.state.user;
        if (user == null) { return null }

        formData.append("givenName", user.givenName);
        if (user.middleName !== undefined) {
            formData.append("middleName", user.middleName);
        }
        formData.append("familyName", user.familyName);
        formData.append("dateOfBirth", user.dateOfBirth);
        formData.append("clinicalRoleId", user.clinicalRoleId);
        formData.append("placeOfWork", user.placeOfWork);
        formData.append("gmcNumber", user.gmcNumber ? user.gmcNumber : "");
        formData.append("expectedEndDate", user.expectedEndDate);
        if (user.phoneNumber !== undefined) {
            formData.append("phoneNumber", user.phoneNumber);
        }
        formData.append("emailAddress", user.emailAddress);

        formData.append("userRoleId", user.userRoleId);
        formData.append("allowAccess", 1);

        return formData;
    }


    // MARK: Render

    render() { 
        return (
            <div className={"user-accounts"}> 
                {this.renderHeader()}
                {this.renderUserDetails()}
                {this.renderAlertModal()}
            </div>
        )
    }


    // MARK: Render Helper Methods

    renderHeader() {
        return (
            <div className={"user-accounts"}> 
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"actions"}>
                            <div className={"spacer"} />
                            <div className={"action"}>
                                {this.renderSaveButton()}
                            </div>
                            <div className={"fixed-space"} />
                            <div className={"action"}>
                                <span className={"btn btn-danger"} onClick={() => this.cancelTapped()}>Cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderSaveButton() {
        if (this.state.userIsReadyForSubmission) {
            return (
                <span className={"btn btn-primary"} 
                    onClick={() => this.saveTapped()}
                >Save and Send Invite</span>
            )
        } else {
            return <span className={"btn btn-primary disabled"}>Save and Send Invite</span>;
        }
    }

    renderUserDetails() {
        let user = this.state.user
        if (user == null) { return }
        return (
            <UserDetailsComponent 
                user={user} 
                userStateDidChange={(user) => this.userStateDidChange(user)} 
                renderExpectedEndDate={true} 
            />
        )
    }

    renderAdminDetails() {
        return (
            <AdminDetailsComponent 
                hasDashboardAccount={this.state.hasDashboardAccount}
                onDashboardAccountStateDidChange={(newState) => this.dashboardAccountStateDidChange(newState)}
            />
        )
    }

    renderAlertModal() {
        return (
            <AlertModal
                open={this.state.modalOpen}
                title={this.state.modalTitle}
                content={this.state.modalContent}
                buttons={this.state.modalButtons}
                dismissHandler={this.hideModal} 
            />
        )
    }
}
 
export default NewUserComponent;